import { Box, useColorModeValue } from '@chakra-ui/react';

export default function HeroSvg(props) {
  const colorModeValue = useColorModeValue('#444', '#fff');
  const { side } = props;
  
  if (side === 'left') {
    return (
      <Box {...props}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 800 600"
          version="1.1"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
            fill: colorModeValue,
          }}
        >
          <rect
            id="left"
            x="0"
            y="0"
            width="800"
            height="600"
            style={{ fill: 'none' }}
          />
          <g id="left1" serifId="left">
            <path d="M62.113,187.275C67.784,184.5 70.525,179.343 71.718,173.157C73.204,165.451 72.226,156.113 72.239,148.29C72.252,130.155 68.209,96.168 74.077,71.47C76.857,59.769 81.84,50.171 90.815,45.701C95.88,43.212 100.374,42.642 104.309,43.112C111.083,43.921 116.151,47.829 119.716,50.063C120.659,50.654 121.484,51.219 122.265,51.457C123.287,51.769 124.085,51.822 124.763,51.626C125.478,51.626 126.058,51.046 126.058,50.331C126.058,49.616 125.478,49.036 124.763,49.036C124.427,48.939 124.286,48.498 123.853,48.196C123.284,47.798 122.56,47.391 121.772,46.87C117.92,44.322 112.152,40.374 104.704,39.591C100.202,39.117 95.068,39.794 89.323,42.716C79.551,47.622 73.936,57.961 70.934,70.727C65.068,95.678 69.11,129.98 69.124,148.29C69.137,155.937 70.114,165.052 68.739,172.598C67.779,177.871 65.666,182.373 60.883,184.815C60.204,185.155 59.929,185.981 60.268,186.66C60.607,187.339 61.434,187.614 62.113,187.275Z" />
            <path d="M55.068,142.077C55.809,143.3 57.38,144.173 59.583,144.553C62.08,144.983 65.522,144.73 69.15,144.123C73.08,143.465 77.32,142.546 80.661,141.199C84.473,139.662 87.114,137.77 87.82,136.858C88.269,136.409 88.269,135.681 87.82,135.233C87.372,134.784 86.644,134.784 86.196,135.233C85.482,135.771 83.159,136.072 80.208,136.886C75.852,138.087 70.189,139.934 65.362,140.576C62.982,140.893 60.856,140.99 59.279,140.717C58.613,140.602 58.028,140.585 57.724,140.218C57.211,139.484 56.199,139.306 55.466,139.819C54.733,140.332 54.554,141.344 55.068,142.077Z" />
            <path d="M105.405,136.976C104.143,138.926 103.531,141.852 103.48,145.023C103.398,150.053 104.614,155.664 105.89,158.237L106.79,160.958C107.016,161.834 107.888,162.381 108.775,162.201C109.662,162.02 110.252,161.177 110.117,160.282L110.081,160.112L110.083,160.112L110.078,160.099C109.97,159.589 109.662,158.077 109.409,156.287C109.126,154.282 108.928,151.926 108.924,150.331L108.249,138.603C108.348,138.465 108.457,138.339 108.573,138.223C110.282,136.518 113.453,135.297 117.047,134.4C119.882,133.693 122.998,133.353 125.918,132.942C128.07,132.64 130.09,132.25 131.906,132.091C132.539,132.091 133.054,131.577 133.054,130.943C133.054,130.31 132.539,129.795 131.906,129.795C130.008,129.629 127.856,129.456 125.588,129.585C122.481,129.76 119.228,130.44 116.24,131.202C113.014,132.026 110.111,133.137 108.014,134.511L107.691,128.902C107.691,128.187 107.111,127.607 106.396,127.607C105.681,127.607 105.1,128.187 105.1,128.902L105.405,136.976Z" />
            <path d="M164.822,130.647C157.116,129.067 149.261,134.546 145.625,141.396C141.719,148.756 142.547,157.509 152.838,161.004C165.168,165.182 175.014,155.509 178.912,144.944C181.892,136.866 181.171,128.422 176.412,125.416C173.573,123.623 169.144,123.532 163.016,126.837C162.439,127.125 162.205,127.828 162.494,128.404C162.782,128.981 163.484,129.215 164.061,128.927C169.068,126.614 172.509,126.502 174.694,128.019C176.274,129.116 177.031,131.074 177.283,133.409C177.613,136.461 177.01,140.136 175.674,143.742C172.34,152.742 164.374,161.481 153.831,158.025C145.775,155.39 145.203,148.511 148.189,142.74C151.214,136.892 157.741,132.002 164.296,133.281C165.022,133.426 165.731,132.954 165.876,132.227C166.021,131.5 165.549,130.792 164.822,130.647Z" />
            <path d="M189.096,156.874C188.987,157.048 188.925,157.254 188.925,157.474C188.925,158.546 189.862,158.655 190.024,158.663C190.166,158.669 190.888,158.673 191.186,157.829C191.228,157.708 191.332,157.372 191.448,156.877C191.89,154.986 192.715,150.445 193.318,148.693C194.148,146.194 194.651,143.729 194.954,141.2C195.375,137.685 195.407,134.047 195.403,130.015C195.677,129.959 195.931,129.802 196.103,129.555C196.464,129.04 196.338,128.33 195.824,127.97L195.386,125.771C195.348,125.102 194.785,124.586 194.116,124.605C193.446,124.624 192.914,125.172 192.914,125.841L192.899,129.923C192.894,133.882 192.863,137.451 192.443,140.896C192.155,143.256 191.682,145.557 190.902,147.887C190.312,149.716 189.453,154.423 189.114,156.408C189.085,156.582 189.08,156.737 189.096,156.874Z" />
            <path d="M197.018,134.83C198.758,133.106 199.782,132.108 200.673,131.392C201.849,130.446 202.792,130.011 204.899,128.97C214.19,124.335 218.578,123.611 220.558,125.669C222.719,127.917 221.612,132.914 220.801,137.898C219.846,143.767 219.204,149.611 221.828,152.245C224.172,154.619 226.095,155.26 227.803,155.185C229.12,155.128 230.343,154.617 231.588,153.998C232.903,153.344 234.25,152.533 235.988,152.488C236.615,152.488 237.125,151.979 237.125,151.351C237.125,150.724 236.615,150.214 235.988,150.214C232.621,150.151 230.386,152.487 227.692,152.585C226.513,152.628 225.255,152.076 223.616,150.458C221.388,148.237 222.454,143.261 223.251,138.294C224.19,132.439 224.875,126.595 222.333,123.96C220.011,121.552 214.68,121.347 203.811,126.794C202.036,127.692 201.026,128.198 200.067,128.863C198.875,129.69 197.761,130.761 195.366,133.178C194.91,133.634 194.91,134.375 195.366,134.83C195.821,135.286 196.562,135.286 197.018,134.83Z" />
            <path d="M262.4,89.937C260.621,100.496 258.732,110.995 257.503,121.71C256.696,128.75 256.172,135.883 256.156,143.188C256.15,148.535 258.076,155.031 261.653,157.132C264.124,158.584 267.479,158.318 271.482,154.193C271.924,153.751 271.924,153.033 271.482,152.591C271.04,152.149 270.322,152.149 269.88,152.591C266.942,155.443 264.706,156.001 262.96,154.943C259.999,153.147 258.681,147.651 258.677,143.188C258.66,135.977 259.142,128.934 259.91,121.981C261.091,111.303 262.942,100.84 264.677,90.317C264.781,89.688 264.356,89.093 263.728,88.989C263.1,88.884 262.505,89.309 262.4,89.937Z" />
            <path d="M249.854,120.976C253.263,122.156 256.267,122.634 259.087,122.64C265.997,122.654 271.688,119.954 279.865,119.843C280.497,119.843 281.01,119.33 281.01,118.698C281.01,118.066 280.497,117.554 279.865,117.554C271.718,117.443 265.981,119.812 259.096,119.784C256.539,119.773 253.807,119.46 250.692,118.461C249.998,118.23 249.247,118.605 249.016,119.3C248.784,119.994 249.16,120.745 249.854,120.976Z" />
            <path d="M296.824,136.262C294.905,136.555 293.236,136.774 291.966,136.937C291.332,137.016 290.881,137.595 290.96,138.23C291.04,138.864 291.619,139.315 292.254,139.236C293.387,139.097 294.831,138.917 296.485,138.682C295.705,146.334 297.778,153.627 303.753,156.636C308.128,158.832 312.96,158.696 317.804,157.375C324.379,155.582 330.962,151.612 336.417,149.363C337.009,149.126 337.297,148.453 337.06,147.861C336.824,147.269 336.15,146.98 335.558,147.217C330.147,149.316 323.579,153.025 317.096,154.732C312.902,155.835 308.74,156.114 304.956,154.23C299.701,151.621 298.278,145.041 299.117,138.288C304.797,137.391 311.984,135.924 317.65,133.547C321.982,131.731 325.422,129.339 326.895,126.397C328.92,122.338 327.068,119.433 323.646,117.673C319.745,115.668 313.632,115.326 310.087,116.508C303.918,118.549 298.83,126.646 297.028,135.196C296.954,135.551 296.885,135.907 296.824,136.262ZM299.518,135.829C303.797,135.102 308.823,134.047 313.336,132.534C318.515,130.798 323.052,128.535 324.672,125.286C325.945,122.746 324.658,120.973 322.515,119.869C319.165,118.142 313.913,117.833 310.867,118.848C305.376,120.691 301.107,128.09 299.539,135.719L299.518,135.829Z" />
            <path d="M357.625,116.448C361.127,119.957 361.921,130.643 360.96,140.834C360.335,147.463 358.9,153.893 356.814,158.102C355.537,160.678 354.131,162.451 352.314,162.463C351.688,162.463 351.18,162.971 351.18,163.596C351.18,164.222 351.688,164.73 352.314,164.73C354.47,164.748 356.487,163.283 358.13,160.622C360.172,157.313 361.707,152.089 362.632,146.312C364.604,134.007 363.639,119.209 359.248,114.826C358.8,114.378 358.073,114.378 357.625,114.826C357.177,115.274 357.177,116.001 357.625,116.448Z" />
            <path d="M365.314,127.768C367.756,125.737 372.196,122.819 375.166,120.584C376.211,119.798 377.082,119.088 377.648,118.523C379.484,116.691 381.779,117.278 383.949,118.617C387.684,120.923 391.106,125.533 392.04,127.407C394.799,132.958 391.729,150.008 395.933,156.951C398.119,160.562 401.998,162.072 408.948,158.496C409.512,158.214 409.741,157.527 409.459,156.963C409.177,156.398 408.49,156.169 407.925,156.451C402.662,159.008 399.759,158.343 398.147,155.625C394.039,148.697 396.985,131.812 394.221,126.316C393.178,124.237 389.368,119.12 385.211,116.567C382.019,114.606 378.65,114.136 375.959,116.833L363.804,125.955C363.304,126.372 363.236,127.117 363.653,127.617C364.069,128.117 364.814,128.185 365.314,127.768Z" />
            <path d="M452.21,135.289C451.697,136.623 451.172,137.926 450.637,139.193C446.972,147.866 442.937,154.874 438.378,157.153C435.62,158.537 433.216,157.055 431.415,154.683C428.579,150.948 427.079,144.953 428.188,141.609C429.946,136.265 432.826,132.397 436.745,130.133C440.593,127.909 445.414,127.228 451.082,128.13C451.782,128.247 452.446,127.773 452.562,127.073C452.679,126.373 452.205,125.71 451.505,125.593C445.144,124.485 439.714,125.247 435.357,127.701C430.853,130.238 427.475,134.598 425.42,140.686C424.073,144.704 425.647,151.973 429.045,156.477C431.761,160.077 435.536,161.92 439.719,159.835C443.695,157.847 447.476,152.69 450.873,145.873C450.258,154.435 451.165,161.315 454.406,164.565C455.854,166.005 458.059,166.529 460.576,166.342C464.967,166.016 470.356,163.599 473.083,162.639C473.681,162.439 474.004,161.792 473.805,161.194C473.606,160.597 472.959,160.273 472.361,160.472C470.084,161.185 465.879,162.846 462.071,163.447C459.764,163.811 457.599,163.891 456.344,162.628C452.751,159.044 452.794,150.494 454.147,140.307C454.269,139.387 454.404,138.454 454.552,137.513C461.451,120.038 466.501,97.323 469.018,88.486L475.121,64.961L477.122,54.647C477.231,54.03 476.903,53.418 476.328,53.167C475.754,52.916 475.082,53.09 474.702,53.589C474.702,53.589 473.178,55.705 471.947,57.918C471.298,59.086 470.724,60.278 470.483,61.251C469.104,66.753 467.435,72.125 466.003,77.619C465.389,79.976 464.818,82.354 464.332,84.775C462.407,94.343 455.364,116.588 452.21,135.289ZM472.619,63.038C471.309,68.14 469.778,73.145 468.454,78.255C467.855,80.567 467.299,82.9 466.827,85.274L466.722,85.782L472.365,64.272L472.619,63.038Z" />
            <path d="M225.944,267.709C225.581,268.999 225.207,270.263 224.823,271.495C222.024,280.489 218.92,287.778 214.909,289.806C207.731,293.44 203.05,293.234 200.439,290.549C197.371,287.393 197.322,281.308 199.263,275.506C202.531,265.736 211.79,256.57 224.17,262.742C224.825,263.07 225.622,262.804 225.95,262.149C226.277,261.494 226.011,260.696 225.356,260.369C211.203,253.272 200.457,263.466 196.673,274.634C194.336,281.532 194.783,288.726 198.406,292.511C201.596,295.843 207.366,296.875 216.25,292.489C220.06,290.605 223.54,284.679 226.41,276.799C226.645,278.555 226.974,280.113 227.408,281.422C228.898,285.824 232.642,290.812 237.261,293.115C240.54,294.75 244.215,295.076 247.718,293.18C248.276,292.9 248.503,292.22 248.224,291.662C247.945,291.103 247.265,290.876 246.706,291.156C243.893,292.449 241.1,291.914 238.601,290.549C234.728,288.434 231.521,284.239 230.282,280.464C229.338,277.65 228.935,273.625 228.911,268.943C233.881,251.323 236.807,228.859 238.34,221.005C239.236,216.394 239.423,210.768 240.366,205.804C240.778,203.638 241.329,201.603 242.184,199.858C242.507,199.203 242.647,198.258 242.639,197.285C242.626,195.659 242.257,193.961 242.257,193.961C242.14,193.409 241.652,193.015 241.088,193.015C240.524,193.016 240.038,193.412 239.922,193.964L238.936,197.913C237.152,203.182 236.325,208.863 234.904,214.343C234.558,215.673 234.176,216.991 233.735,218.287C231.297,225.493 228.035,241.267 226.626,255.722C226.218,259.904 225.973,263.976 225.944,267.709ZM235.469,221.529C233.607,228.161 231.49,239.093 230.18,250.043C232.617,238.318 234.371,227.197 235.469,221.529Z" />
            <path d="M262.106,281.056C265.718,280.067 269.427,279.032 272.512,277.154C274.769,275.78 276.705,273.964 278.062,271.368C280.113,267.264 281.397,260.609 279.842,256.988C278.575,254.036 275.669,252.399 270.066,255.223C265.183,257.644 260.622,267.531 259.499,276.632C258.65,283.514 259.875,289.842 263.706,292.427C267.118,294.722 271.769,295.405 276.626,294.909C283.251,294.233 290.207,291.461 294.656,289.097C295.214,288.818 295.441,288.138 295.162,287.58C294.883,287.022 294.203,286.796 293.645,287.075C289.329,289.101 282.617,291.231 276.336,291.743C272.236,292.076 268.303,291.777 265.412,289.868C263.053,288.314 262.127,284.991 262.106,281.056ZM262.213,278.425C265.344,277.562 268.533,276.664 271.165,274.99C272.989,273.83 274.513,272.272 275.546,270.11C277.266,266.672 278.552,261.13 277.259,258.092C276.503,256.314 274.678,256.007 271.297,257.684C267.619,259.536 264.453,266.346 262.985,273.29C262.621,275.01 262.352,276.746 262.213,278.425Z" />
            <path d="M316.791,292.458C316.549,292.444 316.248,292.442 315.996,292.32C314.609,291.651 312.963,290.256 311.634,288.336C309.997,285.922 308.833,283.36 307.893,280.723C305.579,274.229 304.546,267.314 301.353,261.015C301.055,260.419 300.329,260.178 299.733,260.476C299.137,260.774 298.895,261.499 299.193,262.095C302.271,268.336 303.205,275.163 305.4,281.593C306.378,284.456 307.594,287.242 309.321,289.877C311.479,293.247 314.518,295.3 316.457,295.687C317.852,295.965 318.897,295.506 319.509,294.687C320.134,293.852 320.385,292.106 319.076,289.399C318.835,288.915 319.125,287.439 319.46,285.368C320.268,280.386 322.27,272.663 324.664,266.153C326.233,261.885 327.979,258.176 329.682,256.154C330.534,255.143 331.264,254.463 332.124,255.215C332.567,255.658 333.286,255.658 333.729,255.215C334.172,254.772 334.172,254.053 333.729,253.61C332.249,251.881 330.482,251.909 328.656,253.16C326.862,254.388 324.995,257.318 323.293,261.029C320.13,267.925 317.468,277.711 316.363,283.989C315.745,287.503 315.774,290.079 316.165,290.855C316.472,291.463 316.674,291.981 316.784,292.415L316.791,292.458Z" />
            <path d="M348.238,273.511C345.423,274.161 343.1,274.515 342.11,274.524C341.383,274.524 340.793,275.114 340.793,275.841C340.793,276.568 341.383,277.158 342.11,277.158C342.888,277.166 344.437,276.993 346.401,276.649C343.35,282.559 343.73,287.735 346.24,291.595C349.145,296.064 355.084,298.881 362.518,298.924C364.942,298.93 366.994,298.802 369.01,298.447C371.016,298.093 372.982,297.518 375.259,296.718C377.111,296.059 378.828,295.299 380.468,294.458C384.27,292.507 387.665,290.171 391.596,288.078C392.154,287.799 392.381,287.118 392.102,286.559C391.823,286.001 391.142,285.774 390.583,286.053C386.568,287.933 383.063,290.06 379.193,291.828C377.633,292.541 376.014,293.196 374.267,293.74C372.142,294.41 370.307,294.851 368.453,295.152C366.605,295.452 364.735,295.61 362.518,295.615C356.345,295.651 351.285,293.58 348.844,289.888C346.51,286.356 346.736,281.458 350.41,275.835C355.133,274.738 360.66,272.98 364.074,270.65C368.149,267.867 369.591,264.393 365.675,260.439C363.865,258.631 361.624,258.615 359.297,259.719C355.716,261.417 351.876,266.151 350.286,267.532C349.746,268.002 349.374,268.541 349.116,269.132C348.547,270.433 348.871,272.121 348.238,273.511ZM351.458,272.685C354.301,271.892 357.302,270.856 359.789,269.62C363.542,267.756 366.372,265.566 363.443,262.671C362.319,261.545 360.873,262.3 359.415,263.272C356.472,265.236 353.605,268.745 352.301,269.865C352.013,270.111 351.882,270.427 351.794,270.762C351.632,271.373 351.553,272.027 351.458,272.685Z" />
            <path d="M403.276,204.97C400.228,211.965 398.049,221.56 396.566,231.723C394.44,246.281 393.724,262 393.685,272.78C393.713,279.813 391.739,287.035 393.035,291.864C394.102,295.84 397.039,298.569 404.355,298.412C404.985,298.412 405.497,297.9 405.497,297.27C405.497,296.639 404.985,296.128 404.355,296.128C398.809,296.008 396.505,294.2 395.773,291.166C394.642,286.481 396.631,279.56 396.657,272.78C396.618,262.103 397.247,246.534 399.214,232.097C400.567,222.161 402.541,212.76 405.434,205.895C405.689,205.3 405.413,204.609 404.817,204.354C404.222,204.098 403.531,204.375 403.276,204.97Z" />
            <path d="M437.596,270.584C425.544,264.513 418.762,269.209 416.252,276.104C413.294,284.231 416.671,295.706 424.408,297.669C435.035,300.383 444.767,295.147 448.848,288.123C450.924,284.549 451.627,280.487 450.266,276.736C448.763,272.596 444.734,268.79 437.366,266.603C436.773,266.405 436.131,266.726 435.933,267.32C435.735,267.913 436.056,268.555 436.65,268.753C442.742,271.011 445.97,274.287 447.142,277.821C448.104,280.718 447.638,283.784 446.079,286.534C442.637,292.605 434.272,297.068 425.118,294.829C418.778,293.268 416.479,283.705 418.855,277.042C420.872,271.387 426.529,268.026 436.42,272.935C437.069,273.259 437.859,272.996 438.183,272.347C438.508,271.698 438.244,270.908 437.596,270.584Z" />
            <path d="M472.754,284.064C471.913,283.825 471.152,283.562 470.557,283.273C470.446,283.219 470.328,283.154 470.217,283.089C470.008,278.94 469.605,274.765 468.891,270.527C468.774,269.826 468.11,269.351 467.408,269.468C466.707,269.585 466.232,270.249 466.349,270.951C466.997,274.878 467.37,278.748 467.568,282.593C467.523,282.715 467.498,282.847 467.498,282.984C467.489,283.173 467.529,283.374 467.616,283.583C467.692,285.282 467.735,286.978 467.753,288.671C467.85,297.574 467.285,306.433 467.267,315.637L466.202,336.045L466.138,340.127C466.138,340.921 466.765,341.574 467.558,341.607C468.352,341.64 469.03,341.042 469.097,340.25L470.195,331.202C470.533,329.123 470.782,327.059 470.983,324.997C471.443,320.262 471.65,315.542 472.169,310.7C472.978,303.172 473.659,295.247 474.96,287.564C477.448,288.192 479.896,288.566 480.885,288.584C484.668,288.564 489.202,288.305 492.746,286.666C495.404,285.438 497.538,283.473 498.552,280.369C499.658,277.004 497.901,265.814 495.072,258.831C493.91,255.966 492.521,253.806 491.177,252.956C489.826,252.102 488.445,252.135 487.096,253.48C481.257,259.294 477.567,266.638 475.088,274.694C474.154,277.731 473.393,280.87 472.754,284.064ZM470.332,285.978C470.361,286.866 470.381,287.752 470.394,288.637C470.453,292.663 470.377,296.681 470.277,300.727C470.814,296.074 471.419,291.375 472.242,286.764C471.93,286.659 471.627,286.548 471.337,286.433C470.981,286.292 470.645,286.14 470.332,285.978ZM475.472,284.734C477.752,285.23 479.987,285.56 480.885,285.547C484.273,285.565 488.347,285.444 491.537,284.013C493.494,283.134 495.102,281.741 495.872,279.476C496.77,276.823 495.564,268.808 493.594,262.564C492.626,259.495 491.485,256.845 490.23,255.561C489.809,255.13 489.386,254.919 488.961,255.345C483.457,260.872 480.011,267.853 477.708,275.492C476.805,278.487 476.077,281.582 475.472,284.734Z" />
            <path d="M514.913,276.556C514.084,276.581 513.283,276.595 512.518,276.602C511.812,276.602 511.238,277.175 511.238,277.882C511.238,278.588 511.812,279.162 512.518,279.162C513.324,279.169 514.17,279.17 515.045,279.158C515.648,284.909 518.307,290.167 523.033,292.565C527.891,295.026 532.673,295.219 537.325,294.052C543.773,292.435 549.907,288.199 555.881,285.016C556.44,284.737 556.666,284.057 556.387,283.498C556.108,282.94 555.428,282.713 554.869,282.992C548.951,285.763 542.807,289.399 536.553,290.831C532.599,291.737 528.581,291.767 524.452,289.729C520.65,287.856 518.583,283.676 518.027,279.076C521.792,278.912 525.879,278.476 529.658,277.451C533.739,276.343 537.453,274.536 540.12,271.76C544.666,266.97 544.336,263.331 542.163,260.96C539.245,257.777 531.857,256.764 526.457,258.127C519.703,259.8 515.707,266.83 515.018,274.291C514.948,275.044 514.913,275.801 514.913,276.556ZM517.871,276.421C521.447,276.198 525.316,275.715 528.89,274.694C532.427,273.684 535.678,272.153 537.977,269.718C540.916,266.643 541.396,264.466 540.002,262.934C537.633,260.329 531.558,259.811 527.151,260.902C521.543,262.317 518.474,268.344 517.951,274.55C517.898,275.171 517.871,275.796 517.871,276.421Z" />
            <path d="M568.617,264.814C569.029,262.11 569.46,259.389 569.844,256.625C569.939,255.961 569.477,255.345 568.812,255.25C568.148,255.155 567.532,255.617 567.437,256.281C566.645,261.673 565.683,266.905 565.022,272.167C564.573,275.739 564.262,279.324 564.242,282.984C564.224,284.319 564.388,292.798 564.731,296.143C564.83,297.114 565.024,297.769 565.153,298.005C565.441,298.53 565.864,298.702 566.17,298.788C566.656,298.923 567.183,298.85 567.694,298.364C569.328,296.761 569.77,293.572 569.494,289.576C569.127,284.262 567.695,277.348 568.86,271.675C569.529,268.414 571.09,265.588 574.41,263.911C577.754,262.25 580.758,262.175 583.686,262.856C588.104,263.883 592.314,266.624 597.231,268.311C600.341,269.378 603.704,269.997 607.416,269.829C608.04,269.829 608.546,269.322 608.546,268.698C608.546,268.074 608.04,267.568 607.416,267.568C604.039,267.415 601.103,266.434 598.317,265.347C593.348,263.41 588.904,260.915 584.365,259.891C580.793,259.085 577.137,259.196 573.075,261.24C571.191,262.173 569.733,263.388 568.617,264.814Z" />
            <path d="M334.216,340.178C333.827,340.363 333.294,340.553 332.585,340.744C332.372,340.14 331.708,339.823 331.104,340.037C330.5,340.251 330.184,340.915 330.397,341.519C329.727,341.816 329.194,342.004 328.775,342.104L328.704,342.272C328.525,342.671 328.966,343.105 329.106,343.517C330.539,347.713 332.148,351.767 333.764,355.742C352.38,401.535 379.043,434.327 413.115,457.986C436.083,473.936 462.396,485.885 492.027,494.515C509.725,499.67 528.618,503.544 548.598,506.691C559.551,508.489 570.539,509.763 581.557,510.739C592.548,511.713 603.568,512.391 614.615,512.923C617.229,513.028 626.442,514.577 632.138,514.538C634.655,514.521 636.592,514.095 637.452,513.41C637.59,513.307 638.611,512.28 637.716,510.951C637.586,510.757 637.294,510.363 636.891,509.827C634.844,507.107 629.652,500.506 629.014,499.721C626.94,497.084 624.914,494.437 622.664,491.95C613.796,482.145 603.741,473.47 594.539,463.981C593.287,462.694 591.226,462.665 589.94,463.916C588.653,465.168 588.624,467.229 589.875,468.515C599.105,477.98 608.52,487.279 618.083,496.409C620.464,498.681 622.958,500.793 625.344,503.063C625.974,503.68 630.604,508.353 633.257,511.122C632.352,511.122 631.252,511.034 630.059,510.951C624.456,510.558 617.107,509.174 614.818,509.028C601.634,508.292 588.487,507.386 575.395,505.927C566.692,504.957 558.015,503.734 549.363,502.2C529.692,498.593 511.174,494.097 493.744,488.764C464.886,479.933 439.083,468.535 416.636,452.92C383.505,429.872 357.634,397.951 339.446,353.426C337.856,349.533 336.376,345.523 334.843,341.457C334.692,341.057 334.758,340.455 334.375,340.262L334.216,340.178Z" />
            <path d="M635.989,513.504C634.788,512.968 634.616,512.393 633.305,512.291C631.605,512.159 630.01,513.158 628.371,513.621C613.619,517.79 598.822,521.622 583.757,524.296C579.89,524.983 576.006,525.574 572.122,526.202C569.532,526.621 566.957,527.095 564.358,527.491C562.85,527.653 561.757,529.009 561.92,530.517C562.083,532.025 563.439,533.118 564.947,532.955C567.917,532.765 570.878,532.584 573.83,532.257C585.65,530.95 597.313,528.658 608.843,525.852C617.47,523.753 626.148,521.66 634.469,518.488C634.749,518.381 635.063,518.326 635.286,518.125C636.229,517.275 636.057,516.8 636.687,515.697C637.293,515.505 637.628,514.857 637.435,514.252C637.242,513.646 636.595,513.311 635.989,513.504Z" />
          </g>
        </svg>
      </Box>
    );
  }

  return (
    <Box {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 800 600"
        version="1.1"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinejoin: 'round',
          strokeMiterlimit: 2,
          fill: colorModeValue,
        }}
      >
        <rect
          id="right"
          x="0"
          y="0"
          width="800"
          height="600"
          style={{ fill: 'none' }}
        />
        <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
          <g id="right" transform="matrix(1,0,0,1,833.865,1.59439)">
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1285.64,114.32C1285.31,114.515 1285.08,114.88 1285.08,115.298C1284.96,115.841 1285.26,116.104 1285.32,116.222C1285.53,116.641 1286.62,117.158 1287.46,115.848C1287.78,115.35 1288.39,113.45 1288.85,112.698C1289.65,111.089 1290.4,109.263 1291,107.391C1291.59,105.503 1292.03,103.569 1292.25,101.768C1292.99,95.649 1294.31,87.556 1293.45,80.465C1292.81,75.169 1290.96,70.427 1286.98,67.312C1286.45,66.885 1285.67,66.972 1285.24,67.505C1284.82,68.038 1284.9,68.817 1285.44,69.244C1288.82,72.007 1290.23,76.185 1290.72,80.776C1291.45,87.658 1290.08,95.479 1289.31,101.4C1289.1,103.029 1288.68,104.776 1288.14,106.485C1287.59,108.204 1286.92,109.886 1286.19,111.368C1285.94,111.96 1285.87,113.494 1285.64,114.32ZM1285.59,114.49C1285.5,114.751 1285.39,114.901 1285.24,114.859C1285.07,114.813 1285.44,114.666 1285.48,114.613C1285.51,114.563 1285.55,114.522 1285.59,114.49Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1324.17,86.238C1323.91,85.079 1323.59,84.09 1323.5,83.918C1322.1,81.17 1322.41,77.126 1321.13,74.141C1320.43,72.509 1319.34,71.137 1317.55,70.255C1314.4,68.711 1310.14,69.818 1306.07,72.3C1300.76,75.538 1295.75,80.934 1294.22,84.04C1293.91,84.663 1294.16,85.422 1294.79,85.733C1295.41,86.045 1296.17,85.792 1296.48,85.169C1297.91,82.352 1302.59,77.528 1307.47,74.638C1310.63,72.771 1313.86,71.609 1316.26,72.831C1317.36,73.392 1318,74.27 1318.42,75.285C1319.69,78.35 1319.37,82.466 1320.76,85.29C1320.95,85.679 1320.63,89.709 1320.89,91.049C1321.04,91.812 1321.41,92.245 1321.66,92.432C1322.29,92.903 1323.05,93.072 1323.89,92.244C1326.47,89.655 1327.73,86.095 1328.77,82.51C1329.71,79.247 1330.45,75.96 1332.37,73.701C1333.77,72.055 1335.83,71.044 1339.11,71.024C1339.94,71.021 1341.37,70.864 1342.63,70.945C1343.18,70.98 1343.71,70.972 1343.98,71.242C1344.78,72.036 1345.17,73.162 1345.35,74.375C1345.64,76.365 1345.35,78.59 1344.9,80.339C1343.53,85.728 1340.14,105.751 1343.56,113.202C1345.6,117.626 1349.83,119.079 1356.9,111.536C1357.34,111.089 1357.34,110.364 1356.9,109.917C1356.45,109.47 1355.73,109.47 1355.28,109.917C1353.17,111.815 1351.43,112.927 1350.01,113.481C1348.22,114.179 1347.15,113.455 1346.47,111.913C1343.38,104.856 1346.44,86.109 1347.68,81.033C1348.19,78.958 1348.5,76.318 1348.15,73.96C1347.87,72.117 1347.2,70.443 1345.99,69.232C1345.45,68.685 1344.57,68.32 1343.53,68.169C1342.04,67.955 1340.15,68.147 1339.11,68.144C1334.73,68.117 1332,69.575 1330.1,71.755C1327.9,74.288 1326.88,77.943 1325.78,81.632C1325.31,83.199 1324.83,84.776 1324.17,86.238Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1372.84,83.711C1371.4,84.268 1370.07,85.366 1368.92,86.854C1366.12,90.516 1364.49,96.567 1365.15,101.357C1365.9,106.753 1369.29,110.712 1376.69,108.904C1380.14,108.06 1384.86,102.608 1387.11,97.092C1388.92,92.66 1388.98,88.28 1386.54,86.045C1384.98,84.697 1379.36,82.149 1375.79,82.492C1374.56,82.61 1373.53,82.985 1372.84,83.711ZM1376.24,85.109C1376.04,85.584 1375.57,85.918 1375.03,85.918C1374.78,85.919 1374.53,85.949 1374.29,86.007C1374.24,86.166 1374.2,86.35 1374.2,86.563C1374.2,87.187 1373.69,87.694 1373.07,87.694C1372.69,87.694 1372.37,87.514 1372.16,87.236C1371.78,87.595 1371.41,88.014 1371.06,88.475C1368.68,91.641 1367.33,96.85 1367.95,100.955C1368.5,104.663 1370.89,107.319 1375.97,106.019C1378.38,105.405 1381.29,101.838 1383.26,98.024C1385.18,94.312 1386.49,90.27 1384.41,88.388C1383.38,87.4 1379.95,85.656 1377.17,85.214C1376.85,85.163 1376.54,85.123 1376.24,85.109Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1396.64,85.216C1396.71,86.257 1397.24,87.769 1397.23,88.523L1397.15,100.931L1397.79,106.419C1397.92,107.144 1398.59,107.646 1399.32,107.577C1400.06,107.509 1400.62,106.893 1400.62,106.155L1400.85,100.278C1400.86,98.915 1401.09,97.514 1401.42,96.128C1401.96,93.882 1402.76,91.676 1403.42,89.717C1404.36,86.892 1406.93,86.047 1409.83,85.75C1414.99,85.222 1421.31,86.519 1424.66,86.393C1425.29,86.393 1425.79,85.884 1425.79,85.257C1425.79,84.631 1425.29,84.122 1424.66,84.122C1422.22,84.016 1418.3,82.932 1414.32,82.503C1411.69,82.221 1409.05,82.236 1406.79,82.795C1403.7,83.558 1401.28,85.333 1400.18,88.634L1399.85,89.633L1399.84,88.523C1399.83,87.126 1399.86,84.299 1399.34,83.13C1399.05,82.486 1398.6,82.122 1398.14,81.978C1397.57,81.803 1396.62,81.867 1395.59,83.156C1395.15,83.702 1395.24,84.501 1395.78,84.939C1396.04,85.143 1396.34,85.233 1396.64,85.216Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1439.11,92.657C1439.11,91.732 1439.1,90.793 1439.09,89.829C1439.07,87.798 1438.97,86.271 1438.74,84.699C1438.52,83.232 1438.17,81.724 1437.66,79.722C1437.49,79.036 1436.79,78.618 1436.1,78.79C1435.42,78.961 1435,79.657 1435.17,80.344C1435.63,82.246 1435.93,83.677 1436.12,85.069C1436.31,86.53 1436.37,87.947 1436.35,89.829C1436.33,91.704 1436.36,93.485 1436.2,95.238C1436.1,96.368 1435.92,97.484 1435.62,98.609L1434.8,103.396C1434.72,104.217 1435.28,104.963 1436.08,105.131C1436.89,105.3 1437.69,104.838 1437.95,104.056L1439.91,98.179C1440.35,96.84 1441.07,95.52 1441.9,94.208C1443.94,90.98 1446.64,87.802 1448.2,84.622C1448.57,83.883 1450.33,80.367 1452.4,78.222C1453.31,77.278 1454.16,76.324 1454.98,77.141C1456.4,78.599 1456.8,81.485 1457.07,84.697C1457.46,89.339 1457.3,94.673 1458.01,98.28C1458.32,99.857 1458.77,101.17 1459.45,101.996C1460.29,103.004 1461.29,103.557 1462.54,103.367C1463.16,103.367 1463.67,102.861 1463.67,102.237C1463.67,101.613 1463.16,101.106 1462.54,101.106C1462.25,101.059 1462.17,100.782 1462,100.556C1461.68,100.115 1461.55,99.471 1461.38,98.7C1460.4,94.344 1460.77,86.465 1459.67,80.928C1459.17,78.409 1458.32,76.342 1457.03,75.088C1455.36,73.419 1453.39,73.688 1451.48,75.165C1448.71,77.294 1446.01,82.325 1445.52,83.281C1443.96,86.348 1441.26,89.38 1439.22,92.486L1439.11,92.657Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1471.24,101.305C1471.21,101.394 1471.2,101.487 1471.2,101.584C1470.68,105.942 1473.43,104.534 1473.81,102.747C1474.1,101.38 1474.41,96.548 1474.42,95.706L1473.7,79.311C1473.66,78.555 1473.02,77.971 1472.26,78.009C1471.51,78.047 1470.92,78.692 1470.96,79.448L1471.55,95.706C1471.56,96.349 1471.36,99.447 1471.24,101.305Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1475.44,60.397C1475.42,59.992 1475.19,59.608 1474.81,59.414C1472.15,58.092 1469.53,59.587 1468.22,62.041C1467.03,64.261 1467,67.174 1468.91,69.085C1470.6,70.78 1473.43,69.752 1475.33,67.509C1477.17,65.343 1477.83,62.345 1476.42,60.929C1476.25,60.76 1475.88,60.566 1475.44,60.397ZM1471.21,61.878C1470.82,62.209 1470.49,62.644 1470.23,63.119C1469.5,64.486 1469.35,66.293 1470.53,67.47C1471.39,68.329 1472.6,67.157 1473.57,66.011C1474.57,64.822 1475.56,63.342 1474.78,62.566C1474.59,62.376 1473.61,62.633 1473.26,62.396C1473.05,62.687 1472.71,62.878 1472.33,62.878C1471.75,62.878 1471.27,62.441 1471.21,61.878Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1487.41,93.071L1487.4,91.788L1487.22,77.421C1487.22,76.772 1486.69,76.245 1486.05,76.245C1485.4,76.245 1484.87,76.772 1484.87,77.421L1484.7,91.788L1484.61,100.278L1483.21,110.074C1483.2,111.136 1483.9,111.452 1484.23,111.576C1484.57,111.702 1484.98,111.734 1485.43,111.52C1485.58,111.45 1485.93,111.241 1486.22,110.763C1486.64,110.075 1487.53,107.852 1487.54,107.761C1487.54,107.761 1488.79,99.382 1488.79,99.382C1489.89,95.456 1490.63,91.496 1492.1,87.923C1493.04,85.636 1494.28,83.51 1496.15,81.68C1496.91,80.935 1499.09,78.645 1501.28,77.43C1502.31,76.863 1503.32,76.366 1504.03,77.073C1505.3,78.389 1505.72,80.504 1505.86,82.902C1506.18,88.425 1504.76,95.407 1504.74,99.625C1504.74,100.858 1504.78,102.499 1505.13,103.904C1505.46,105.206 1506.03,106.313 1506.75,106.944C1507.96,108.002 1509.86,108.427 1512.32,105.651C1512.76,105.208 1512.76,104.49 1512.32,104.047C1511.88,103.605 1511.16,103.605 1510.71,104.047C1509.87,104.803 1509.29,105.17 1508.8,105.102C1508.35,105.039 1508.45,104.375 1508.3,103.771C1507.96,102.469 1507.84,100.826 1507.84,99.625C1507.82,95.358 1509.24,88.276 1508.79,82.697C1508.54,79.539 1507.68,76.838 1505.94,75.156C1504.69,73.904 1503.01,73.798 1501.21,74.482C1498.4,75.552 1495.23,78.733 1494.23,79.692C1492.05,81.777 1490.58,84.202 1489.46,86.826C1488.62,88.812 1488,90.917 1487.41,93.071ZM1484.07,108.745C1483.75,108.838 1483.3,109.002 1483.6,109.18C1483.77,109.279 1483.93,109.066 1484.07,108.745Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1545.32,86.597C1545.58,89.697 1545.57,92.892 1545.9,95.877C1546.57,102.134 1547.72,110.824 1547.62,118.954C1547.55,124.407 1546.91,129.599 1545.18,133.551C1544.1,135.954 1541.92,137.954 1539.44,139.164C1537.04,140.333 1534.3,140.778 1531.83,139.745C1530.88,139.383 1529.97,138.942 1529.07,138.472C1526.85,137.317 1524.73,135.968 1522.65,134.63C1522.15,134.252 1521.43,134.354 1521.05,134.858C1520.67,135.362 1520.78,136.078 1521.28,136.456C1522.65,137.675 1524,138.943 1525.47,140.078C1527,141.261 1528.65,142.266 1530.39,143.096C1533.84,144.611 1537.76,144.257 1541.12,142.583C1544.37,140.964 1547.07,138.127 1548.38,134.922C1550.2,130.583 1550.83,124.922 1550.84,118.978C1550.86,110.715 1549.7,101.89 1548.97,95.535C1548.51,91.467 1548.65,87.009 1547.91,82.93C1547.57,80.982 1547.03,79.117 1546.16,77.403C1545.67,76.423 1544.94,76.342 1544.57,76.356C1544.14,76.372 1543.21,76.678 1542.95,77.946C1542.66,79.412 1542.9,84.39 1542.81,85.03C1542.45,87.11 1540.79,93.765 1538.08,98.487C1536.47,101.275 1534.66,103.577 1532.24,102.758C1527.81,101.296 1527.85,97.916 1529.41,94.303C1532.07,88.12 1538.79,81.256 1540.67,78.966C1541.17,78.372 1541.08,77.49 1540.49,76.998C1539.9,76.505 1539.01,76.587 1538.52,77.181C1536.18,79.983 1526.96,89.4 1525.61,96.533C1524.85,100.486 1526.11,103.894 1531.28,105.634C1534.29,106.62 1537.01,105.11 1539.19,102.171C1542.47,97.756 1544.59,90.081 1545.32,86.597ZM1545.29,79.176C1545.83,78.982 1546.42,78.674 1545.88,78.557C1545.58,78.491 1545.39,78.739 1545.29,79.176Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1355.83,182.713L1355.82,184.523L1356.49,193.665L1356.51,197.584L1356.51,197.741L1356.54,197.894L1356.58,198.042L1356.63,198.183L1356.69,198.318L1356.77,198.445L1356.86,198.564L1356.96,198.673L1357.07,198.772L1357.18,198.861L1357.31,198.938L1357.45,199.003L1357.59,199.055L1357.74,199.093L1357.89,199.116L1358.05,199.124L1358.2,199.116L1358.36,199.093L1358.5,199.055L1358.65,199.003L1358.78,198.938L1358.91,198.861L1359.03,198.772L1359.14,198.673L1359.24,198.564L1359.32,198.445L1359.4,198.318L1359.47,198.183L1359.52,198.042L1359.56,197.894L1359.58,197.741L1359.59,197.584L1359.6,193.665L1359.57,193.186L1359.57,193.012C1359.56,191.287 1359.53,189.642 1359.43,188.012C1359.34,186.56 1359.19,185.119 1358.96,183.643C1358.94,179.538 1358.93,175.622 1359.56,172.024L1359.76,172.153C1361.33,173.107 1363.12,174.423 1365.19,175.01C1366.54,175.393 1368,175.437 1369.55,175.022C1372.98,174.1 1376.44,168.903 1377.71,163.912C1378.78,159.698 1378.15,155.813 1375.63,154.581C1374.26,153.891 1372.62,153.963 1371,154.481C1369,155.116 1367.01,156.419 1365.76,157.24C1362.17,159.59 1359.88,162.396 1358.41,165.544L1358.33,164.87C1357.91,161.336 1357.29,157.807 1356.77,154.291C1356.67,153.552 1355.98,153.038 1355.24,153.143C1354.5,153.249 1353.99,153.934 1354.1,154.673C1354.58,158.168 1355.17,161.676 1355.55,165.188C1355.81,167.497 1355.98,169.807 1355.96,172.114C1355.95,174.562 1355.66,177.3 1355.67,179.906C1355.68,180.865 1355.72,181.807 1355.83,182.713ZM1360.19,169.28C1360.47,169.365 1360.76,169.467 1361.05,169.584C1362.56,170.187 1364.22,171.238 1366.08,171.704C1366.91,171.913 1367.79,172.041 1368.75,171.819C1370.98,171.304 1373.05,168.218 1374.32,165.031C1375.09,163.099 1375.55,161.09 1375.48,159.445C1375.43,158.336 1375.2,157.391 1374.42,156.995C1373.65,156.612 1372.74,156.769 1371.83,157.067C1370.1,157.637 1368.39,158.83 1367.31,159.561C1363.88,161.887 1361.87,164.676 1360.69,167.806C1360.5,168.29 1360.34,168.781 1360.19,169.28Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1389.58,168.002C1388.98,168.073 1388.44,168.129 1387.96,168.176C1387.23,168.245 1386.69,168.896 1386.76,169.628C1386.83,170.359 1387.48,170.897 1388.21,170.828L1389.08,170.745C1388.62,174.924 1389.71,178.637 1392.59,180.088C1394.76,181.11 1396.89,181.171 1398.94,180.582C1401.61,179.815 1404.11,177.961 1406.35,176.324C1406.87,175.977 1407.01,175.271 1406.66,174.75C1406.32,174.228 1405.61,174.087 1405.09,174.435C1402.98,175.716 1400.63,177.181 1398.21,177.704C1396.84,178.002 1395.44,177.946 1394.03,177.202C1392,176.192 1391.82,173.361 1392.31,170.369C1395.05,169.993 1398.31,169.393 1401.21,168.418C1404.75,167.227 1407.72,165.417 1408.96,162.96C1410.6,159.725 1409.71,157.85 1408.09,156.819C1405.36,155.082 1398.57,156.375 1395.9,157.694C1393.34,158.958 1390.72,163.39 1389.58,168.002ZM1392.94,167.543C1395.3,167.165 1397.93,166.61 1400.31,165.785C1403.04,164.836 1405.45,163.589 1406.39,161.676C1406.9,160.659 1407.12,159.986 1406.8,159.52C1406.56,159.176 1406.09,159.062 1405.56,158.964C1403.01,158.494 1399.01,159.524 1397.26,160.413C1395.47,161.319 1393.87,164.311 1392.94,167.543Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1424.41,164.689C1424.3,162.584 1424.14,160.466 1423.91,158.279C1423.84,157.612 1423.25,157.125 1422.58,157.192C1421.91,157.258 1421.42,157.854 1421.49,158.521C1421.73,161.063 1421.88,163.509 1421.96,165.952C1422.04,168.411 1422.06,170.869 1422.04,173.421L1421.87,180.604C1421.87,181.359 1422.43,181.993 1423.18,182.08C1423.93,182.166 1424.63,181.677 1424.8,180.942L1426.28,175.05C1426.77,172.623 1426.78,169.759 1427.25,167.16C1427.43,166.168 1427.68,165.216 1428.1,164.365C1428.91,162.655 1430.82,161.804 1432.95,161.242C1436.6,160.283 1440.97,160.278 1443.6,160.188C1444.22,160.188 1444.73,159.679 1444.73,159.053C1444.73,158.427 1444.22,157.919 1443.6,157.919C1440.82,157.823 1436.18,157.488 1432.26,158.295C1429.09,158.951 1426.43,160.421 1425.14,162.884C1424.85,163.455 1424.61,164.06 1424.41,164.689Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1462.33,156.488C1461.13,156.677 1459.89,156.632 1458.7,156.771C1458.08,156.842 1457.48,156.958 1456.89,157.148C1450.66,159.184 1450.39,162.1 1452.12,165.053C1453.96,168.178 1459.28,171.732 1462.05,175.276C1463.13,176.662 1463.85,177.998 1463.17,179.29C1462.92,179.76 1462.32,179.889 1461.64,179.995C1460.46,180.18 1459.03,179.983 1457.63,179.654C1455.98,179.266 1454.39,178.669 1453.4,177.796C1453.01,177.443 1452.62,177.14 1452.57,176.686C1452.57,176.059 1452.06,175.55 1451.43,175.55C1450.81,175.55 1450.3,176.059 1450.3,176.686C1450.18,177.746 1450.61,178.782 1451.4,179.734C1452.54,181.101 1454.63,182.216 1456.85,182.795C1460.67,183.79 1464.72,182.902 1465.82,180.613C1466.91,178.334 1466.12,175.937 1464.2,173.567C1461.44,170.163 1456.3,166.687 1454.49,163.653C1453.51,162.012 1454.27,160.831 1457.73,159.652L1462.75,159.007C1463.44,158.891 1463.91,158.232 1463.8,157.537C1463.68,156.842 1463.02,156.372 1462.33,156.488Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1493.18,161.147C1493.47,161.08 1493.77,161.025 1494.07,160.981C1494.77,160.88 1495.26,160.228 1495.16,159.524C1495.06,158.821 1494.4,158.331 1493.7,158.432C1486.42,159.43 1480,166.121 1478.23,172.439C1476.71,177.834 1478.35,182.944 1485.03,184.659C1488.98,185.668 1496.81,183.13 1501.2,178.908C1504.65,175.595 1506.05,171.395 1502.93,167.286C1502.21,166.363 1496.5,162.142 1493.18,161.147ZM1489.49,162.569C1486.3,164.325 1483.64,167.227 1482.06,170.297C1479.62,175.04 1479.84,180.336 1485.75,181.774C1489.04,182.578 1495.38,180.147 1499.03,176.645C1501.28,174.479 1502.52,171.812 1500.51,169.106C1500.04,168.456 1496.85,166.04 1494.19,164.507C1493.35,164.023 1492.23,163.214 1491.74,163.138C1491.66,163.683 1491.18,164.102 1490.62,164.102C1489.99,164.102 1489.49,163.596 1489.49,162.972C1489.48,162.831 1489.48,162.696 1489.49,162.569ZM1491.7,163.136C1491.57,163.118 1491.49,163.17 1491.49,163.312C1491.49,163.452 1491.63,163.305 1491.7,163.136Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1516.88,171.546L1516.78,166.237C1516.78,165.492 1516.18,164.888 1515.43,164.888C1514.69,164.888 1514.09,165.492 1514.09,166.237L1513.98,172.114C1513.95,173.154 1513.98,174.167 1513.84,175.12C1513.77,175.653 1513.63,176.162 1513.38,176.639C1513.1,177.174 1512.43,178.19 1512.33,178.722C1512.22,179.264 1512.35,179.702 1512.54,180.032C1512.8,180.496 1513.26,180.894 1514.13,180.913C1514.84,180.925 1515.53,180.614 1516.14,180.011C1517.16,179.01 1517.93,176.747 1518.25,176.132C1519.37,173.847 1520.58,171.698 1521.85,169.577C1523.12,167.449 1524.46,165.349 1525.87,163.183C1526.19,162.704 1527.01,161.187 1528.05,160.113C1528.5,159.654 1528.9,159.007 1529.38,159.479C1530.89,161.003 1531.13,164.142 1531.21,167.545C1531.35,173.148 1530.8,179.453 1531.92,182.397C1533.12,185.577 1536.27,186.901 1542.73,180.471L1546.66,176.374C1547.75,175.002 1548.93,173.84 1550.22,172.785C1551.52,171.72 1552.9,170.748 1554.47,169.86C1555.03,169.581 1555.26,168.902 1554.98,168.344C1554.7,167.786 1554.02,167.559 1553.46,167.838C1551.66,168.626 1550.02,169.491 1548.52,170.539C1546.97,171.618 1545.6,172.899 1544.29,174.385L1540.38,178.125C1539.28,179.228 1538.33,180.072 1537.5,180.687C1536.03,181.777 1535.35,182.185 1534.97,181.224C1533.91,178.503 1534.5,172.648 1534.33,167.456C1534.19,163.132 1533.47,159.245 1531.53,157.322C1530.25,156.038 1528.87,156.031 1527.55,156.693C1525.65,157.641 1523.82,160.662 1523.28,161.454C1521.5,164.072 1519.81,166.589 1518.25,169.192C1517.78,169.967 1517.32,170.75 1516.88,171.546ZM1514.21,177.687L1514.13,177.684C1514.02,177.686 1513.75,177.649 1513.81,177.748C1513.93,177.963 1514.07,177.904 1514.21,177.687Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1299.41,156.307C1299.11,156.127 1299.07,155.987 1298.74,155.899C1298.61,155.865 1298.47,155.899 1298.34,155.911C1297.03,156.036 1295.73,156.326 1294.43,156.539C1279.77,158.936 1265.07,161.671 1250.69,165.464C1234.6,169.708 1218.92,175.295 1204.11,183.136C1194.28,188.369 1185.15,194.585 1176.36,201.286C1156.15,216.695 1137.74,234.64 1116.31,248.399C1095.23,262.084 1070.3,271.83 1044.46,278.08C1026.53,282.419 1008.15,285.22 990.25,286.095C982.281,286.485 974.41,286.442 966.728,286.136C965.779,286.103 964.982,286.846 964.949,287.794C964.915,288.743 965.658,289.54 966.607,289.574C974.381,289.811 982.338,289.674 990.401,289.312C1008.53,288.499 1027.2,286.299 1045.42,282.145C1071.85,276.118 1097.32,266.272 1118.95,252.51C1140.48,238.666 1159.03,220.677 1179.3,205.132C1187.87,198.561 1196.74,192.421 1206.32,187.262C1217.86,181.018 1229.97,176.218 1242.38,172.312C1259.24,167.006 1276.68,163.462 1294.06,160.213C1295.81,159.886 1297.77,160.117 1299.3,159.214C1299.6,159.038 1299.58,158.876 1299.8,158.609C1300.43,158.502 1300.86,157.899 1300.75,157.264C1300.65,156.629 1300.05,156.2 1299.41,156.307Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1351.17,424.007C1349.4,422.789 1347.81,421.546 1345.93,420.563C1345.47,420.325 1344.98,420.161 1344.5,419.974C1334.74,416.167 1324.79,413.654 1314.88,411.729C1289.44,406.794 1263.88,406.493 1239.02,405.171C1213.61,403.82 1188.91,401.486 1165.79,391.699C1117.81,371.383 1086.49,325.569 1043,299.418C1039.98,297.605 1036.85,296.006 1033.72,294.382C1026.5,290.641 1019.19,286.806 1011.12,284.648C1009.85,284.308 1008.56,284.126 1007.26,283.893C1006.66,283.715 1006.03,284.057 1005.85,284.655C1005.67,285.253 1006.01,285.883 1006.61,286.06C1008.42,286.772 1010.14,287.586 1011.92,288.3C1028.21,294.843 1042.96,303.264 1056.57,313.758C1092.12,341.157 1121.63,378.291 1163.71,396.539C1175.65,401.729 1188.01,405.03 1200.68,407.185C1226.98,411.657 1254.61,411.312 1282.52,413.538C1300.16,414.946 1317.89,417.447 1335.53,422.822C1338.37,423.685 1341.12,424.865 1344.04,425.602C1346.05,426.109 1348.02,426.229 1350.08,426.623C1350.8,426.924 1351.63,426.583 1351.93,425.861C1352.23,425.139 1351.89,424.308 1351.17,424.007Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M955.448,278.449C959.19,275.946 968.379,272.06 970.076,271.046C979.51,265.445 988.773,259.371 997.766,252.948C1002.56,249.525 1007.28,246.008 1011.91,242.412C1012.37,242.051 1012.9,241.757 1013.29,241.323C1013.96,240.597 1014.28,239.809 1014.81,238.984C1015.46,238.473 1015.57,237.536 1015.06,236.891C1014.55,236.246 1013.61,236.136 1012.97,236.646C1012.02,236.995 1011.15,237.142 1010.26,237.63C1009.47,238.069 1008.82,238.742 1008.1,239.301C995.214,249.313 981.791,258.952 967.904,267.447C966.374,268.381 958.764,271.746 954.518,274.251C952.148,275.65 950.811,277.214 950.674,277.994C950.176,278.57 950.243,279.257 950.388,280.514C950.487,281.366 951.263,282.304 952.023,283.386C954.902,287.488 960.904,293.352 962.275,295.007C967.064,300.744 971.626,306.442 976.005,312.228C981.931,320.06 987.475,328.084 992.668,336.693C993.006,337.254 993.185,337.953 993.685,338.382C994.219,338.84 994.583,338.738 995.235,339.007C995.718,339.848 996.792,340.138 997.633,339.656C998.473,339.173 998.764,338.099 998.281,337.258C998.395,336.631 998.614,336.451 998.532,335.821C998.483,335.446 998.23,335.134 998.055,334.8C994.886,328.744 991.033,323.24 987.23,317.774C980.9,308.677 974.025,300.06 966.688,291.311C965.167,289.515 957.904,282.443 955.67,278.787C955.594,278.663 955.526,278.546 955.448,278.449Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1367.09,346.071C1367.15,345.762 1367.1,345.428 1366.91,345.145C1366.53,344.578 1365.77,344.425 1365.2,344.803C1362.99,346.285 1361.06,349.15 1360.64,352.003C1360.33,354.097 1360.8,356.178 1362.41,357.79C1365.61,361.046 1368.71,357.416 1369.61,352.971C1369.96,351.246 1369.99,349.49 1369.67,348.298C1369.32,346.999 1368.55,346.233 1367.62,346.087C1367.46,346.062 1367.28,346.054 1367.09,346.071ZM1367.1,348.526C1367.14,348.538 1367.19,348.537 1367.18,348.507C1367.12,348.196 1366.5,348.819 1366.04,349.249C1365.59,349.695 1364.87,349.695 1364.42,349.249C1364.39,349.218 1364.36,349.185 1364.34,349.151C1363.89,349.816 1363.54,350.546 1363.3,351.297C1362.76,352.969 1362.83,354.766 1364.13,356.072C1365.43,357.356 1366.16,355.636 1366.71,353.946C1367.19,352.476 1367.4,350.771 1367.27,349.503C1367.25,349.26 1367.22,349.034 1367.17,348.836C1367.14,348.749 1367.13,348.61 1367.1,348.526C1367.06,348.514 1367.01,348.488 1367.03,348.468C1367.06,348.434 1367.09,348.466 1367.1,348.526Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1366.1,372.831C1366.06,373.129 1366.03,373.457 1366.03,373.812L1366.03,373.944C1366.02,374.376 1366.13,375.7 1366.15,375.737L1366.73,379.507L1369.27,388.516C1370.33,393.89 1370.97,399.226 1371.33,404.622C1371.7,409.968 1371.8,415.373 1371.78,420.931C1371.76,429.238 1371.02,438.299 1369.11,447.038C1367.21,455.731 1364.15,464.107 1359.48,471.081C1355.4,477.168 1350.21,479.296 1345.55,478.11C1340.88,476.922 1336.82,472.542 1334.99,465.716C1334.83,465.108 1334.22,464.738 1333.61,464.89C1333,465.042 1332.63,465.659 1332.78,466.267C1334.64,474.284 1339.39,479.461 1344.81,480.928C1350.45,482.454 1356.92,480.064 1361.84,472.657C1366.67,465.379 1369.82,456.66 1371.75,447.609C1373.66,438.673 1374.37,429.415 1374.35,420.931C1374.34,415.312 1374.21,409.85 1373.82,404.448C1373.42,398.949 1372.75,393.513 1371.64,388.04C1371.39,386.786 1371.07,385.514 1370.71,384.255C1370.17,382.36 1369.55,380.493 1368.96,378.762C1368.62,377.755 1368.81,377.016 1368.7,376.201C1368.64,375.802 1368.54,375.39 1368.35,374.945L1368.35,373.974L1368.35,373.951L1368.35,373.91L1368.35,373.651C1368.32,371.694 1367.82,370.801 1367.29,370.778C1366.8,370.628 1366.28,371.254 1366.1,372.831Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1395.17,381.99C1395,381.488 1394.52,381.127 1393.96,381.127C1390.77,381.106 1384.47,384.105 1381.74,387.675C1379.39,390.734 1379.17,394.151 1384.21,396.675C1387.24,398.188 1389.78,397.53 1391.85,395.824C1393.9,394.126 1395.47,391.247 1396.52,388.617C1397.26,392.002 1398.59,396.956 1400.62,399.607C1402.65,402.265 1405.18,403.17 1407.83,400.181C1408.27,399.739 1408.27,399.02 1407.83,398.578C1407.38,398.135 1406.67,398.135 1406.22,398.578C1404.34,400.308 1403.19,398.498 1402.12,396.263C1400.16,392.166 1398.85,385.966 1398.6,384.739L1397.94,379.043C1397.89,378.304 1397.26,377.74 1396.52,377.773C1395.78,377.807 1395.2,378.424 1395.21,379.165C1395.21,379.165 1395.25,380.449 1395.17,381.99ZM1395.11,382.958C1394.9,383.382 1394.47,383.673 1393.96,383.673C1391.31,383.69 1386.15,386.334 1383.9,389.318C1382.61,391.018 1382.64,392.851 1385.43,394.248C1387.29,395.178 1388.85,394.779 1390.12,393.73C1392.32,391.916 1393.78,388.478 1394.64,385.89C1394.86,385.218 1394.99,384.289 1395.08,383.349L1395.11,382.958Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1427.25,403.584L1425.27,393.236L1423.9,386.573L1419.9,374.242C1419.73,373.623 1419.08,373.263 1418.46,373.44C1417.84,373.617 1417.48,374.264 1417.66,374.884L1421.5,387.37L1422.74,393.768L1424.67,404.256C1424.99,405.837 1425.61,406.616 1426.1,406.931C1427.06,407.553 1428.87,406.951 1430.13,404.419C1432.72,399.205 1435.58,385.642 1435.85,384.824C1436.09,384.087 1436.54,382.595 1437.12,381.237C1437.37,380.684 1437.63,380.16 1437.9,379.722C1438.1,379.401 1438.11,378.997 1438.37,378.967C1439,378.967 1439.51,378.457 1439.51,377.829C1439.51,377.2 1439,376.69 1438.37,376.69C1437.38,376.582 1436.29,377.272 1435.4,378.491C1434.22,380.112 1433.44,382.797 1433.06,383.895C1432.82,384.618 1430.4,395.394 1428.17,401.379C1427.85,402.236 1427.55,402.991 1427.25,403.584Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1461.3,387.933C1460.91,389.689 1460.27,391.419 1459.38,392.976C1457.84,395.666 1455.54,397.841 1452.39,398.622C1450.97,398.976 1448.83,398.141 1447.16,396.9C1445.64,395.775 1444.37,394.28 1444.95,392.686C1446.18,389.197 1447.86,386.133 1450.2,383.93C1452.52,381.743 1455.48,380.423 1459.27,380.391C1459.96,380.391 1460.53,379.828 1460.53,379.135C1460.53,378.441 1459.96,377.878 1459.27,377.878C1454.74,377.84 1451.17,379.383 1448.36,381.953C1445.69,384.393 1443.71,387.794 1442.25,391.706C1441.41,393.992 1442.34,396.35 1444.21,398.204C1446.67,400.627 1450.68,402.048 1453.09,401.443C1457.04,400.449 1459.96,397.77 1461.87,394.393C1462.04,394.099 1462.2,393.8 1462.35,393.495C1462.59,394.278 1462.89,395.019 1463.24,395.691C1464.94,398.919 1467.74,400.797 1472.04,399.147C1472.63,398.949 1472.95,398.308 1472.75,397.716C1472.56,397.123 1471.91,396.803 1471.32,397C1468.51,397.8 1466.95,396.424 1465.95,394.334C1465.03,392.399 1464.53,389.899 1464.27,387.525C1464.5,386.238 1464.61,384.95 1464.6,383.706L1464.61,375.869C1464.61,375.086 1463.97,374.451 1463.19,374.451C1462.38,374.448 1461.42,375.146 1461.13,376.707C1460.8,378.538 1461.08,381.982 1461.08,382.4C1461.07,383.893 1461.1,385.866 1461.3,387.933Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1492.36,370.018C1491.17,370.206 1489.93,370.159 1488.73,370.298C1488.12,370.369 1487.52,370.485 1486.93,370.676C1480.78,372.708 1480.37,375.743 1482.04,378.937C1483.83,382.346 1489.05,386.285 1492.08,389.995C1493.36,391.565 1494.32,393.011 1493.85,394.363C1493.38,395.73 1491.09,396.311 1488.67,396.743C1486.19,397.187 1483.46,397.224 1481.6,396.775C1481.15,396.666 1480.75,396.567 1480.44,396.393C1480.26,396.288 1480.02,396.295 1480,396.114C1480,395.489 1479.49,394.982 1478.86,394.982C1478.24,394.982 1477.73,395.489 1477.73,396.114C1477.61,397.25 1478.29,398.245 1479.58,398.987C1480.87,399.732 1482.99,400.126 1485.27,400.124C1490.15,400.12 1495.63,398.037 1496.52,395.253C1497.21,393.129 1496.18,390.713 1494.16,388.277C1491.22,384.729 1486.18,380.947 1484.46,377.667C1483.49,375.824 1484.23,374.417 1487.78,373.226L1492.79,372.578C1493.5,372.46 1493.98,371.791 1493.86,371.085C1493.74,370.378 1493.07,369.9 1492.36,370.018Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1520.84,374.757C1514.92,377.217 1507.33,386.369 1505.84,393.1C1504.54,398.959 1507.02,403.601 1517.68,401.489C1518.88,401.212 1520.05,400.802 1521.17,400.278C1522.75,399.542 1524.25,398.618 1525.74,397.779C1526.29,397.499 1526.52,396.82 1526.24,396.262C1525.96,395.704 1525.28,395.477 1524.73,395.756C1523.21,396.42 1521.68,397.137 1520.1,397.725C1519.12,398.091 1518.13,398.403 1517.1,398.576C1509.97,400.017 1507.86,397.647 1508.68,393.713C1509.94,387.659 1516.53,379.301 1521.79,376.982C1522.4,376.719 1522.69,376.007 1522.42,375.393C1522.16,374.779 1521.45,374.494 1520.84,374.757Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1536.16,385.504C1536.12,383.642 1536.18,381.746 1536.18,379.788C1536.18,379.153 1535.66,378.638 1535.03,378.638C1534.39,378.638 1533.88,379.153 1533.88,379.788C1533.87,382.247 1533.76,384.608 1533.86,386.93C1533.93,388.577 1534.11,390.206 1534.51,391.835C1534.61,392.231 1534.69,392.712 1534.75,393.228C1534.68,393.749 1534.65,394.338 1534.66,394.932C1534.7,396.573 1535,398.233 1535,398.233C1535.08,398.946 1535.71,399.467 1536.43,399.414C1537.15,399.361 1537.7,398.754 1537.68,398.036L1537.66,397.915L1537.67,397.914L1537.78,393.21C1538.39,390.734 1538.62,387.932 1539.25,385.333C1539.5,384.311 1539.81,383.321 1540.27,382.408C1540.8,381.328 1541.64,380.825 1542.59,380.567C1544.59,380.022 1546.99,380.354 1548.74,380.271C1549.37,380.271 1549.88,379.762 1549.88,379.135C1549.88,378.507 1549.37,377.998 1548.74,377.998C1546.79,377.906 1544.16,377.277 1541.91,377.759C1540.15,378.135 1538.62,379.124 1537.62,381.086C1537.07,382.19 1536.67,383.385 1536.36,384.623C1536.29,384.915 1536.22,385.208 1536.16,385.504Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1561.89,399.857L1561.9,399.857L1561.25,392.196C1561.22,390.463 1561.29,388.645 1561.44,386.811C1561.65,384.053 1562.01,381.26 1562.34,378.652C1562.44,377.993 1561.98,377.382 1561.32,377.288C1560.66,377.193 1560.05,377.652 1559.95,378.311C1559.54,380.949 1559.11,383.776 1558.81,386.569C1558.61,388.484 1558.47,390.382 1558.44,392.196L1559.09,400.209C1559.18,400.95 1559.84,401.492 1560.58,401.445C1561.33,401.399 1561.91,400.78 1561.91,400.033L1561.89,399.857Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1560.27,357.006C1561.75,358.771 1562.65,359.149 1563.1,359.805C1563.26,360.037 1563.28,360.331 1563.28,360.849C1563.28,361.473 1563.79,361.979 1564.41,361.979C1565.04,361.979 1565.54,361.473 1565.54,360.849C1565.55,359.677 1565.33,359.047 1564.97,358.523C1564.5,357.832 1563.57,357.41 1562.02,355.549C1561.62,355.067 1560.9,355.001 1560.42,355.403C1559.94,355.805 1559.87,356.524 1560.27,357.006Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1578.04,384.534C1577.45,384.577 1576.99,385.068 1576.99,385.665C1576.91,386.914 1577.11,388.017 1577.51,388.974L1577.17,391.985C1577.09,392.716 1577,393.446 1576.91,394.176L1576.77,395.291L1576.71,383.706C1576.71,383.045 1576.18,382.508 1575.52,382.508C1574.86,382.508 1574.32,383.045 1574.32,383.706L1574.19,407.869C1574.18,408.701 1574.17,409.612 1574.13,410.548L1573.36,414.678L1573.09,416.552C1573.02,416.706 1572.95,416.854 1572.87,416.997C1572.71,417.3 1572.61,417.712 1572.57,418.159C1572.5,418.97 1572.68,419.95 1572.68,419.95C1572.84,420.708 1573.53,421.234 1574.3,421.188C1575.08,421.142 1575.7,420.538 1575.77,419.766L1576.24,416.317C1576.42,415.59 1576.55,414.816 1576.64,414.019L1576.7,413.411L1578.23,404.232C1578.7,401.749 1579.05,399.232 1579.34,396.702C1579.53,395.088 1579.7,393.469 1579.86,391.85L1580.18,392.073C1583.01,393.903 1587.28,393.94 1590.33,392.876C1592.13,392.205 1593.22,390.449 1593.63,388.115C1594.32,384.158 1593.2,378.552 1592.23,376.654C1591.38,374.935 1589.89,372.519 1587.99,371.264C1586.48,370.267 1584.74,369.927 1582.82,370.88C1581.56,371.499 1580.71,372.586 1580.13,373.873C1579.39,375.508 1579.12,377.482 1578.87,378.931C1578.54,380.781 1578.28,382.652 1578.04,384.534ZM1580.22,388.233C1580.88,389.057 1581.85,389.609 1582.9,389.985C1584.98,390.723 1587.49,390.821 1589.44,390.21C1590.8,389.802 1591.23,388.108 1591.39,386.246C1591.68,383.014 1590.86,379.137 1590.14,377.698C1589.44,376.289 1588.26,374.263 1586.69,373.241C1585.87,372.705 1584.93,372.498 1583.89,373.022C1583.14,373.399 1582.68,374.089 1582.34,374.86C1581.71,376.308 1581.52,378.059 1581.31,379.338C1580.84,382.266 1580.52,385.243 1580.22,388.233Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1612.87,356.514C1613.74,361.076 1613.75,365.254 1613.3,369.32C1612.58,375.695 1610.75,381.795 1609.31,388.636C1608.12,394.694 1610.54,397.532 1613.69,398.162C1616.65,398.751 1620.62,397.222 1623.34,394.306C1623.78,393.862 1623.78,393.142 1623.34,392.698C1622.9,392.255 1622.18,392.255 1621.73,392.698C1619.53,394.751 1616.54,395.819 1614.33,395.288C1612.41,394.828 1611.5,392.921 1612.26,389.226C1613.58,382.271 1615.29,376.055 1615.9,369.587C1616.3,365.277 1616.23,360.856 1615.23,356.042C1615.1,355.39 1614.46,354.967 1613.81,355.098C1613.16,355.228 1612.74,355.862 1612.87,356.514Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1604.17,375.038L1622.46,376.344C1623.08,376.388 1623.62,375.919 1623.66,375.297C1623.71,374.675 1623.24,374.133 1622.62,374.089L1604.33,372.783C1603.71,372.738 1603.17,373.207 1603.12,373.83C1603.08,374.452 1603.55,374.993 1604.17,375.038Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1412.05,471.544C1411.99,471.366 1411.9,471.198 1411.76,471.058C1411.29,470.595 1410.91,470.531 1410.77,470.52C1410.27,470.482 1409.14,470.874 1409.6,472.243C1409.85,472.992 1411.31,475.669 1411.66,476.854C1412.06,478.043 1412.32,479.606 1412.5,481.244C1412.78,483.704 1412.88,486.332 1412.87,488.196C1412.87,495.567 1414.01,503.926 1413.68,511.944C1413.47,517.161 1412.62,522.23 1410.34,526.75C1407.01,533.189 1403.11,534.443 1399.15,532.909C1392.72,530.422 1386.36,521.737 1383.22,515.772C1382.94,515.214 1382.26,514.987 1381.7,515.266C1381.15,515.545 1380.92,516.225 1381.2,516.783C1384.35,523.406 1390.99,533.196 1397.96,535.949C1403.18,538.01 1408.69,536.634 1412.86,528.009C1415.28,523.127 1416.19,517.671 1416.4,512.052C1416.71,503.996 1415.55,495.601 1415.55,488.196C1415.54,486.249 1415.44,483.505 1415.14,480.937C1414.92,479.103 1414.59,477.357 1414.14,476.028C1413.76,474.989 1412.79,472.032 1412.05,471.544Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1410.59,453.489C1410.37,453.207 1410.02,453.027 1409.64,453.027C1406.32,453.02 1404.27,454.984 1403.42,457.409C1402.52,459.995 1403.06,463.113 1404.85,464.907C1406.75,466.818 1410.19,465.013 1412.21,461.818C1414.16,458.734 1414.45,454.927 1412.15,453.782C1411.63,453.539 1411.11,453.452 1410.59,453.489ZM1406.93,456.398C1406.37,456.882 1405.97,457.517 1405.73,458.213C1405.14,459.917 1405.41,461.983 1406.6,463.158C1406.89,463.455 1407.28,463.283 1407.67,463.057C1408.55,462.562 1409.41,461.581 1410.11,460.486C1410.79,459.4 1411.31,458.193 1411.4,457.171C1411.45,456.665 1411.44,456.198 1411.04,455.997C1410.81,455.87 1410.56,455.893 1410.32,455.953C1409.37,456.186 1408.44,456.695 1407.68,456.679C1407.39,456.679 1407.13,456.573 1406.93,456.398Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1439.31,488.099C1439.3,489.184 1439.4,490.218 1439.63,491.148C1440.14,493.024 1441.76,494.27 1443.79,494.721C1446.42,495.307 1449.61,494.563 1450.99,492.846C1451.39,492.371 1451.34,491.657 1450.86,491.253C1450.38,490.849 1449.67,490.907 1449.27,491.382C1448.45,492.288 1446.79,492.551 1445.28,492.369C1443.94,492.208 1442.62,491.675 1442.34,490.469C1442.01,489.146 1442.02,487.563 1442.18,485.946C1442.44,483.355 1443.1,480.682 1443.58,478.722C1443.7,478.217 1443.75,477.531 1443.75,476.838C1443.75,475.594 1443.61,474.325 1443.61,474.325C1443.53,473.681 1443,473.187 1442.35,473.159C1441.7,473.131 1441.13,473.577 1441,474.211L1439.68,480.032C1439.36,481.299 1437.31,486.602 1434.81,490.475C1433.92,491.843 1433,493.037 1432.05,493.739C1431.51,494.133 1431.01,494.405 1430.5,494.153C1428.49,493.163 1427.77,490.283 1427.37,487.058C1426.75,482.067 1427.22,476.167 1427.2,473.176C1427.2,472.491 1426.65,471.936 1425.96,471.936C1425.28,471.936 1424.73,472.491 1424.73,473.176C1424.7,476.738 1423.99,484.301 1425.12,489.801C1425.78,493.005 1427.13,495.521 1429.27,496.607C1431.34,497.653 1433.81,496.428 1435.92,493.724C1437.16,492.149 1438.32,490.108 1439.31,488.099Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1462.7,489.129C1462.86,488.197 1462.97,487.3 1463.05,486.387C1463.15,485.298 1463.2,484.188 1463.18,482.971C1463.18,481.305 1463.15,480.323 1463.08,479.466C1462.98,478.157 1462.79,477.146 1462.51,474.354C1462.44,473.648 1461.81,473.132 1461.1,473.202C1460.4,473.273 1459.88,473.903 1459.95,474.61C1460.22,477.37 1460.4,478.369 1460.5,479.663C1460.56,480.473 1460.59,481.4 1460.58,482.971C1460.57,484.094 1460.51,485.118 1460.4,486.121C1460.28,487.153 1460.1,488.161 1459.87,489.231C1459.31,491.823 1459.05,493.519 1459,494.502C1458.94,495.715 1459.33,496.349 1459.57,496.599C1460.08,497.11 1460.71,497.266 1461.48,496.907C1461.89,496.718 1462.66,495.931 1463.41,494.561C1465.93,489.958 1470.39,477.563 1471.24,476.698C1471.53,476.41 1474.21,474.738 1475.11,473.375L1475.12,473.525C1475.26,475.43 1474.76,477.825 1475,479.262C1475.68,483.468 1476.34,491.328 1479.09,497.023C1481.19,501.352 1484.44,504.441 1489.31,504.351C1489.94,504.351 1490.45,503.842 1490.45,503.216C1490.45,502.59 1489.94,502.082 1489.31,502.082C1485.46,502.011 1483.15,499.308 1481.54,495.859C1478.98,490.36 1478.19,482.874 1477.5,478.844C1477.27,477.47 1477.77,475.178 1477.64,473.355C1477.56,472.1 1477.2,471.03 1476.5,470.316C1475.89,469.702 1474.77,469.592 1473.53,470.473C1471.96,471.594 1469.74,474.475 1469.38,474.831C1468.67,475.521 1465.35,483.416 1462.7,489.129ZM1461.68,494.432C1461.67,494.792 1461.74,494.985 1461.95,494.96C1462.37,494.908 1462.06,494.646 1461.68,494.432ZM1460.41,494.078C1460.08,494.074 1459.79,494.129 1460,494.345C1460.12,494.469 1460.25,494.347 1460.41,494.078Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1505.76,429.223C1503.7,441.172 1500.35,453.222 1498.54,465.436C1497.32,473.655 1496.81,481.946 1497.75,490.306C1497.94,491.921 1498.32,493.404 1498.81,494.808C1499.95,498.072 1501.67,500.888 1502.58,504.155C1502.74,504.763 1503.36,505.128 1503.96,504.97C1504.57,504.813 1504.94,504.192 1504.78,503.584C1503.98,500.242 1502.37,497.315 1501.32,493.975C1500.93,492.73 1500.61,491.425 1500.47,490.004C1499.57,481.878 1500.13,473.829 1501.29,465.838C1503.04,453.638 1506.2,441.566 1508.13,429.618C1508.24,428.963 1507.8,428.343 1507.14,428.234C1506.49,428.124 1505.87,428.568 1505.76,429.223Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1501.17,476.88C1503.41,478.051 1505.91,477.684 1508.43,476.484C1511.45,475.05 1514.55,472.43 1517.25,470.669C1518.5,469.848 1519.62,469.153 1520.66,469.112C1521.3,469.112 1521.82,468.592 1521.82,467.951C1521.82,467.31 1521.3,466.79 1520.66,466.79C1519.25,466.735 1517.58,467.357 1515.83,468.403C1513.14,470.013 1510.17,472.626 1507.26,474.075C1505.53,474.938 1503.84,475.449 1502.27,474.696C1501.66,474.395 1500.93,474.639 1500.63,475.242C1500.33,475.844 1500.57,476.578 1501.17,476.88Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1501.99,479.089C1505.9,485.041 1510.34,491.478 1515.71,496.758C1519.38,500.367 1523.5,503.403 1527.98,505.55C1528.55,505.833 1529.24,505.603 1529.52,505.036C1529.81,504.469 1529.58,503.779 1529.01,503.495C1524.83,501.316 1521.13,498.216 1517.69,494.763C1512.5,489.557 1507.96,483.463 1504.06,477.711C1503.68,477.14 1502.91,476.986 1502.34,477.366C1501.77,477.747 1501.61,478.519 1501.99,479.089Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1536.48,469.91C1536.44,476.262 1535.66,482.769 1535.7,489.143C1535.72,491.981 1535.91,494.792 1536.36,497.551C1536.7,499.477 1537.36,502.362 1538.78,504.362C1539.9,505.943 1541.45,507.017 1543.52,506.97C1544.15,506.97 1544.66,506.459 1544.66,505.829C1544.66,505.199 1544.15,504.687 1543.52,504.687C1542.29,504.66 1541.46,503.909 1540.83,502.954C1539.68,501.221 1539.17,498.765 1538.91,497.126C1538.47,494.498 1538.3,491.82 1538.27,489.117C1538.18,482.755 1538.84,476.25 1538.8,469.91C1538.8,469.269 1538.28,468.748 1537.64,468.748C1537,468.748 1536.48,469.269 1536.48,469.91Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1536.4,452.526C1536.67,453.842 1536.75,454.814 1536.99,455.756C1537.18,456.486 1537.46,457.197 1537.92,458.013C1538.2,458.578 1538.89,458.807 1539.46,458.525C1540.02,458.242 1540.25,457.555 1539.97,456.991L1538.88,452.029C1538.74,451.344 1538.08,450.898 1537.39,451.035C1536.7,451.173 1536.26,451.84 1536.4,452.526Z" />
            </g>
            <g transform="matrix(1,0,0,1,-833.865,-1.59439)">
              <path d="M1557.56,487.551C1560.95,486.733 1565.3,485.46 1568.98,483.429C1571.62,481.975 1573.91,480.122 1575.39,477.881C1577.14,475.232 1577.15,473.235 1576.4,471.831C1575.78,470.672 1574.56,469.816 1572.94,469.322C1568.96,468.105 1562.39,469.073 1559.76,470.672C1555.17,473.423 1552.47,480.989 1552.34,488.592C1552.19,496.438 1554.83,504.12 1559.78,506.6C1561.72,507.516 1563.81,507.742 1565.97,507.499C1570.25,507.018 1574.77,504.694 1578.41,503.687C1579.03,503.532 1579.41,502.902 1579.25,502.282C1579.1,501.663 1578.47,501.285 1577.85,501.44C1574.77,502.128 1571.02,503.74 1567.37,504.361C1565.2,504.731 1563.07,504.736 1561.21,503.751C1557.1,501.698 1555.46,495.139 1555.51,488.634C1555.56,482.133 1557.35,475.495 1561.23,473.067C1563.39,471.734 1568.84,470.937 1572.14,471.954C1572.95,472.205 1573.63,472.546 1573.94,473.133C1574.33,473.892 1573.98,474.891 1573.03,476.307C1571.77,478.178 1569.78,479.651 1567.57,480.882C1564.12,482.803 1560.08,484.1 1556.9,484.923C1556.18,485.104 1555.74,485.84 1555.92,486.565C1556.1,487.29 1556.83,487.732 1557.56,487.551Z" />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}
